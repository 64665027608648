import React from 'react';
import { ThemeProvider } from '@emotion/react';
import DocumentProvider from './src/contexts/DocumentProvider';
import theme from './src/constants/theme';
import Layout from './src/components/Layout';
import './src/fonts/fonts.css';

export const wrapPageElement = ({ element, props }) => (<Layout {...props}>{element}</Layout>);

export const wrapRootElement = ({element}) => (
    <ThemeProvider theme={theme}>
      <DocumentProvider>{element}</DocumentProvider>
    </ThemeProvider>
);
