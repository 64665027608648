import React, {createContext, useEffect, useState} from 'react';
import {throttle} from 'lodash';

type Device = 'mobile' | 'tablet' | 'desktop';
type Props = {children: React.ReactNode};

export const DocumentContext = createContext<{device: Device}>({device: 'mobile'});

export default function DocumentProvider({children}: Props) {
  const [device, setDevice] = useState<Device>('mobile');

  function handleResize() {
    let detectedDevice: Device = 'desktop';
    if (window.matchMedia(`(min-width: 769px) and (max-width: 1024px)`).matches) {
      detectedDevice = `tablet`;
    } else if (window.matchMedia(`(max-width: 768px)`).matches) {
      detectedDevice = `mobile`;
    }
    setDevice(detectedDevice);
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.addEventListener) {
      window.addEventListener('resize', throttle(handleResize, 50), false);
      handleResize();
    }

    return () => {
      if (typeof window !== 'undefined' && window?.removeEventListener) {
        window.removeEventListener('resize', handleResize, false);
      }
    };
  }, []);

  return (
    <DocumentContext.Provider
      value={{
        device,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
}
