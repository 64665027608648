const theme = {
  color: {
    black: '#000000',
    lightRed: '#EFBEBE',
    orange: '#FE7213',
    infinetBlue1: '#02ABFE',
    infinetBlue2: '#005EB8',
    white: '#FFFFFF',
  },
} as const;

export default theme;
