import React, {useEffect, useState} from 'react';
import {Global} from '@emotion/react';

type Props = {children: React.ReactNode};

export default function Layout({children}: Props) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <Global
        styles={[
          {
            '*': {
              boxSizing: `inherit`,
              '&:before': {
                boxSizing: `inherit`,
              },
              '&:after': {
                boxSizing: `inherit`,
              },
            },
            html: {
              fontFamily: 'Arial, sans-serif',
              fontSize: `18px`,
              WebkitTextSizeAdjust: `100%`,
              scrollBehavior: 'smooth',
            },
            body: {
              margin: 0,
            },
            button: {
              border: 'none',
              margin: 0,
              padding: 0,
              width: 'auto',
              overflow: 'visible',
              background: 'transparent',
              color: 'inherit',
              font: 'inherit',
              lineHeight: 'normal',
              cursor: 'pointer',
            },
            img: {
              borderStyle: `none`,
            },
            a: {
              color: 'inherit',
              textDecoration: 'none',
            },
            'h1, h2, h3, h4, h5, h6, p': {
              fontWeight: 'normal',
              marginBlockStart: 0,
              marginBlockEnd: 0,
            },
          },
        ]}
      />
      {children}
    </>
  );
}
